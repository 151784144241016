import React from 'react';
import equal from 'fast-deep-equal';

import EnhancedTableRow from './EnhancedTableRow';

function CustomTableRow({ ...props }) {
  return <EnhancedTableRow {...props} />
}

function areEqual(prevProps, nextProps) {
  return equal(prevProps.cellData, nextProps.cellData) &&
    equal(prevProps.rowCells, nextProps.rowCells) &&
    prevProps.selected === nextProps.selected
}

export default React.memo(CustomTableRow, areEqual);
