import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/AlertDialog';
import { months, monthName } from 'constants';
import { firestoreListener } from 'modules/firebase';
import { unique } from 'modules/uitls';
import DatePickerField from 'components/DatePickerField';

const EditWeeklyPolicyView = forwardRef((props, ref) => {
  const { weeklyPolicy } = props
  const { formatMessage } = useIntl()
  const numberRule = /[^0-9]/g

  const [weeklyPolicyData, setWeeklyPolicyData] = useState({
    name: weeklyPolicy.name ?? '',
    note: weeklyPolicy.note ?? '',
    year: dayjs(weeklyPolicy.year).format('YYYY') ?? '',
    ...weeklyPolicy.earned
  })

  const [dialogOpen, setDialogOpen] = useState(false)
  const [policyYearMapping, setPolicyYearMapping] = useState([])

  const fields = [
    { name: 'name', required: true, md: 3 },
    { name: 'year', type: 'date', md: 3, required: true, },
    { name: 'note', md: 6, }
  ]

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'weeklyPolicies',
      onData: (data) => {
        setPolicyYearMapping(unique(data.reduce((acc, cur) => {
          acc.push(cur.year)
          return acc
        }, [])))
      }
    })

    return () => unsubscribe()
  }, []);

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  function updateWeeklyPolicyData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    if (weeklyPolicyData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...weeklyPolicyData, [field.name]: newValue, [`${field.name}_err`]: err }
    setWeeklyPolicyData(newData)
  }

  function _getContent() {
    let err = false

    let newData = { ...weeklyPolicyData }
    for (let field of fields) {
      const errMsg = validateField(field, newData[field.name])
      if (errMsg) {
        newData[`${field.name}_err`] = errMsg
      }
    }

    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        err = true
        break
      }
    }

    if (err) {
      setWeeklyPolicyData(newData)
      return
    }

    if (policyYearMapping.includes(dayjs(newData.year).format('YYYY'))) {

      return setDialogOpen(true)
    }

    return {
      name: newData.name,
      note: newData.note,
      year: dayjs(newData.year).format('YYYY'),
      earned: months.reduce((acc, cur) => {
        const dd = newData[cur]
        acc[cur] = Number(dd)
        return acc
      }, {})
    }
  }

  function getRow(month) {
    return (
      <div key={month} style={{ margin: '16px 0' }}>
        <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
          {/* <Grid item xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}> */}
          <Grid item xs={5}>
            <div style={{ display: 'flex', justifyContent: 'center', }}>{monthName[month]}</div>
          </Grid>
          <Grid item xs={3} sx={{ margin: '16px, 0' }}>
            <TextField
              required
              type="text"
              label="當月休假天數"
              variant="outlined"
              value={weeklyPolicyData[month]}
              onChange={e => updateWeeklyPolicyData({ name: month, allowCharacter: numberRule }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <div>天</div>
          </Grid>
          {/* </div>
          </Grid> */}
        </Grid>
      </div>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {dialogOpen && <AlertDialog
        dialogTital={formatMessage({ id: 'setAttendance.weeklyPolicy.alert.title' })}
        dialogMsg={formatMessage({ id: 'setAttendance.weeklyPolicy.alert.msg' })}
        handleClose={() => setDialogOpen(false)}
      />}
      <Grid container spacing={1}>
        {fields.map(field => field.type === 'date' ?
          <Grid item key={field.name} xs={6} sm={12} md={field.md}>
            <DatePickerField
              fullWidth
              margin="dense"
              closeToolbar={false}
              required={field.required}
              label={formatMessage({ id: `setAttendance.editWeeklyPolicy.table.detail.${field.name}` })}
              inputFormat='YYYY'
              value={weeklyPolicyData[field.name] !== '' ? dayjs(weeklyPolicyData[field.name]) : null}
              onChange={date => updateWeeklyPolicyData(field, date)}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid> : <Grid item key={field.name} xs={6} sm={12} md={field.md}>
            <TextField
              required={field.required}
              type="text"
              size="small"
              label={formatMessage({ id: `setAttendance.editWeeklyPolicy.table.detail.${field.name}` })}
              variant="outlined"
              value={weeklyPolicyData[field.name]}
              onChange={e => updateWeeklyPolicyData(field, e.target.value)}
              error={weeklyPolicyData[`${field.name}_err`] ? true : false}
              helperText={weeklyPolicyData[`${field.name}_err`]}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="setAttendance.editWeeklyPolicy.table.title" />
        </Typography>
      </div>
      <Paper sx={{ overflow: 'hidden' }}>
        {months.map(m => getRow(m))}
      </Paper>
    </div>
  );
})

EditWeeklyPolicyView.displayName = 'EditWeeklyPolicyView'

EditWeeklyPolicyView.propTypes = {
  weeklyPolicy: PropTypes.object,
};

export default EditWeeklyPolicyView;
