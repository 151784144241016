import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';

import TabContainer from 'containers/TabContainer/TabContainer';
import ContextStore from 'modules/context';
import { firestoreListener } from 'modules/firebase';
import Profile from './Profile';
import CustomerTabPage from './CustomerTabPage';
import { useModuleMapping } from 'hooks/modules';

function CustomerTab() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate();
  const location = useLocation();
  const { customerId, tabName } = useParams()
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const [customer, setCustomer] = useState(null)
  const userMapping = useSelector(state => state.users.data);
  const [customerMapping, setCustomerMapping] = useState({})
  const [appointment, setAppointment] = useState([])
  const productMapping = useSelector(state => state.products.data);

  const [storedValueCard, setStoredValueCard] = useState(null)
  const [ppData, setPPData] = useState([]);
  const [ppMapping, setPPMapping] = useState({});
  // const [salesOrders, setSalesOrders] = useState([]);
  const [salesOrderMapping, setSalesOrderMapping] = useState({});
  const [consumedServices, setConsumedServices] = useState({});
  const [transferServices, setTransferServices] = useState({});
  const [transformServices, setTransformServices] = useState([]);
  const [refundServices, setRefundServices] = useState({})
  const moduleMapping = useModuleMapping()

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'consumedServiceForms',
      where: [['customer', '==', customerId]],
      onData: (data) => {
        const newData = data.filter(i => !i.void).map(i => ({ ...i, dataType: 'consume' }))
        setConsumedServices(newData)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'transferServices',
      where: [['customer', '==', customerId]],
      onData: (data) => {
        const newData = data.map(i => ({ ...i, dataType: 'transfer' }))
        setTransferServices(newData)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'transformServices',
      where: [['customer', '==', customerId]],
      onData: (data) => {
        const newData = data.map(i => ({ ...i, dataType: 'transform' }))
        setTransformServices(newData)
      }
    })

    return () => unsubscribe()
  }, []);


  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'refundServices',
      where: [['customer', '==', customerId]],
      onData: (data) => {
        const newData = data.map(i => ({ ...i, dataType: 'refund' }))
        setRefundServices(newData)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'salesOrders',
      where: [['customer', '==', customerId]],
      mapping: true,
      onDoc: (docData) => {
        const products = Object.keys(docData.products).map(k => docData.products[k])
        const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
        const collected = Object.keys(docData.payments || {})
          .map(k => docData.payments[k]).filter(p => !p.void).map(p => p.collected)
          .reduce((acc, cur) => acc + cur, 0)
        if (total === collected) {
          docData.status = 'fullPayment'
        } else if (collected === 0) {
          docData.status = 'noPayment'
        } else {
          docData.status = 'partialPayment'
        }

        if (docData.void) {
          docData.status = 'void'
        }
        docData.uncollected = total - collected
        docData.collected = collected
      },
      onData: (mapping) => {
        // setSalesOrders(data)
        setSalesOrderMapping(mapping)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'appointments',
      where: [['customer', '==', customerId]],
      onData: (data) => {
        setAppointment(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      doc: customerId,
      onData: (data) => {
        setCustomer(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'purchasedProducts',
      where: [['customer', '==', customerId]],
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setPPData(data)
        setPPMapping(mapping)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/customers',
      text: formatMessage({ id: 'sideMenu.customers.root' })
    }]
    breadcrumbs.push({ text: formatMessage({ id: `customer.tab.${tabName}` }) })

    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'storedValueCard',
      doc: customerId,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setStoredValueCard(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    if (tabName === 'profile') {
      setActiveTabIndex(0)
    } else if (tabName === 'appointment') {
      setActiveTabIndex(1)
    } else if (tabName === 'purchasedProduct') {
      setActiveTabIndex(2)
    } else if (tabName === 'consumption') {
      setActiveTabIndex(3)
    }
    return () => {
    };
  }, []);

  if (!customer) {
    return ('Loading...')
  }

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate(`/customer/${customerId}/profile`);
    } else if (tabIndex === 1) {
      navigate(`/customer/${customerId}/appointment`);
    } else if (tabIndex === 2) {
      navigate(`/customer/${customerId}/purchasedProduct`);
    } else if (tabIndex === 3) {
      navigate(`/customer/${customerId}/consumption`);
    }
  };

  // 'appointment', 'purchasedProduct', 'consumption'
  const _customerTabs = [{ text: 'profile', order: 0 }]
  if (moduleMapping.appointment) {
    _customerTabs.push({ text: 'appointment', order: 1 })
  }

  if (moduleMapping.sales) {
    _customerTabs.push({ text: 'purchasedProduct', order: 2 })
    _customerTabs.push({ text: 'consumption', order: 3 })
  }

  const customerTabs = _customerTabs.sort((a, b) => a.order - b.order)
  const customerTabData = {
    'appointment': {
      data: appointment,
      cells: ['date', 'arrangeTreatments', 'doctor', 'nurse', 'salesRep', 'staytime']
    },
    'purchasedProduct': {
      data: ppData.filter(pp => !pp.salesOrderVoid),
      cells: ['date', 'productName', 'quantityAvailable', 'salesOrderSN', 'source', 'status'],
      mappingData: {
        'salesOrder': salesOrderMapping,
        'product': productMapping,
      }
    },
    'consumption': {
      data: consumedServices.concat(transferServices).concat(refundServices).concat(transformServices).reduce((acc, cur) => {
        if (cur.dataType === 'consume') {
          for (const key of Object.keys(cur.services || {})) {
            acc.push({ ...cur, productData: { ...cur.services[key] }, id: key })
          }
        } else if (cur.dataType === 'refund') {
          for (const key of Object.keys(cur.products || {})) {
            if (productMapping[cur.products[key].productId].type === 'service') {
              acc.push({ ...cur, productData: { ...cur.products[key] }, id: key })
            }
          }
        } else if (cur.dataType === 'transform') {
          acc.push({ ...cur, productData: { ...cur, productId: cur.product } })
        } else {
          for (const key of Object.keys(cur.products || {})) {
            acc.push({ ...cur, productData: { ...cur.products[key] }, id: key })
          }
        }

        return acc
      }, []),
      cells: ['date', 'productName', 'amount', 'typeName'],
      mappingData: {
        'product': productMapping,
        'pp': ppMapping,
        'customer': customerMapping
      }
    }
  }
  const tabs = customerTabs.reduce((acc, cur) => {
    if (cur.text === 'profile') {
      acc.push({
        label: formatMessage({ id: `customer.tab.${cur.text}` }), component:
          <Profile
            customer={customer}
            userMapping={userMapping || {}}
            customerMapping={customerMapping}
            productMapping={productMapping}
            storedValueCard={storedValueCard || {}}
          />
      })
    } else {
      acc.push({
        label: formatMessage({ id: `customer.tab.${cur.text}` }), component:
          <CustomerTabPage
            currentData={customerTabData[cur.text].data}
            userMapping={userMapping || {}}
            currentCells={customerTabData[cur.text].cells}
            mappingData={customerTabData[cur.text].mappingData || {}}
            tabName={cur.text}
          />
      })
    }

    return acc
  }, [])

  let defaultSelect
  if (tabName === 'profile') {
    defaultSelect = 0
  } else if (tabName === 'appointment') {
    defaultSelect = 1
  } else if (tabName === 'purchasedProduct') {
    defaultSelect = 2
  } else if (tabName === 'consumption') {
    defaultSelect = 3
  }

  return (
    <div style={{ width: '100%', fontFamily: 'Roboto, sans-serif', }}>
      <div style={{ padding: '40px 20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
          <div style={{ fontSize: '34px', fontWeight: 400, color: '#888', }}>{customer.name}</div>
          <div style={{ fontSize: '14px', fontWeight: 500, color: '#828a99', marginLeft: '35px' }}><CakeIcon sx={{ marginRight: '5px' }} />{customer.birthDate}</div>
          <div style={{ fontSize: '14px', fontWeight: 500, color: '#828a99', marginLeft: '35px' }}><PhoneIcon sx={{ marginRight: '5px' }} />{customer.phone}</div>
        </div>
        <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
      </div>
    </div>
  );
}

export default CustomerTab;
