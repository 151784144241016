const i18n = {
  'purchaseOrder': {
    'tab': {
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'name': '採購單',
    'table': {
      'pending': {
        'title': '採購單列表 - 待簽核',
      },
      'done': {
        'title': '採購單列表 - 未結單',
      },
      'title': '採購單列表',
      'selected': '已選擇 {count} 筆採購單',
      'header': {
        'sn': '採購單號',
        'source': '採購單位',
        'sourceName': '採購單位',
        'createdBy': '採購人',
        'date': '建立日期',
        'expectedDate': '需求日期',
        'supplier': '供應商',
      },
      'detail': {
        'sn': '採購單號',
        'source': '採購單位',
        'sourceName': '採購單位',
        'supplier': '供應商',
        'basicPurchaseAmount': '最低採購金額',
        'freeShippingAmount': '免運金額',
        'createdBy': '採購人',
        'date': '建立日期',
        'expectedDate': '需求日期',
        'note': '備註',
        'merchandise': '商品',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
        'extraMerchandises': '贈品',
        'shipping': '收貨單位',
      }
    },
    'print': {
      'title': '採購單',
      'sn': '採購單號',
      'date': '填表日期',
      'expectedDate': '需求日期',
      'createdBy': '申請人',
      'supplierFullName': '廠商',
      'billRule': '付款方式',
      'supplierPhone': '電話',
      'supplierContactName': '聯絡人',
      'address': '公司地址',
      'phone': '電話',
      'businessNumber': '統編',
      'shippingAddress': '送貨地址',
      'contactPhone': '電話',
      'contactName': '收件人',
      'merchandise': {
        'name': '品名',
        'require': '需求量',
        'unitPrice': '單價',
        'orderUnit': '單位',
        'amount': '數量',
        'price': '金額',
        'note': '備註',
      }
    },
    'dialog': {
      'title': {
        'add': '新增採購單',
        'edit': '編輯採購單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'orderUnit': '單位',
      'amount': '數量',
      'delivery': '已到貨數量',
      'price': '金額',
      'note': '備註',
    }
  },
  'editPurchaseOrder': {
    'table': {
      'title': '商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'addMerchandise': '新增商品',
    'removeMerchandise': '移除商品',
  }
}
export default i18n
