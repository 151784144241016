import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';

import DateRangePickerField from 'components/DateRangePickerField';
import EnhancedButton from 'components/EnhancedButton';
import SalesOrderInfoDiablog from 'components/SalesOrderInfoDiablog';
import SelectProductDialog from 'components/SelectProductDialog';
import FilterMenu from 'components/FilterMenu';
import ContextStore from 'modules/context';
import { firestoreListener, firestoreDoc } from 'modules/firebase';
import CompanyFilter from 'components/CompanyFilter';
import QueryPPListTable from 'pages/Sales/QueryPPListTable';
import QuerySOTotalAmountTable from 'pages/Sales/QuerySOTotalAmountTable';

const FlexRow = styled('div')(() => ({
  padding: '2px 4px',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
}));

function SalesOrderQueryWrap() {
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)

  return products.length ? <SalesOrderQuery productMapping={productMapping} products={products} /> :
    <div>請先建立產品</div>
}

function SalesOrderQuery({ productMapping, products }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [soHistory, setSoHistory] = useState({});
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const userMapping = useSelector(state => state.users.data)
  const [query, setQuery] = useState({});
  const [queryCondition, setQueryCondition] = useState({ filter: '', condition:'{}' });
  const [resultData, setResultData] = useState([])
  const [salesOrderInfo, setSalesOrderInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [soTotalAmount, setSoTotalAmount] = useState('0');
  const [soTotalAmountError, setSoTotalAmountError] = useState('');
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [currentFilter, setCurrentFilter] = useState({
    name: 'product',
    text: '產品'
  });
  const filterItems = [
    { name: 'product' },
    { name: 'salesOrderTotalAmount' },
    { name: 'salesOrderPaymentAmount' },
    { name: 'salesOrderPurePaymentAmount' },
  ].map(i => { i.text = formatMessage({ id: `salesOrder.filter.${i.name}` }); return i })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.sales.query' })
    }])

    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const condition = JSON.parse(queryCondition.condition)
    let unsubscribe = null;
    if (queryCondition.filter === 'product' && condition.product && condition.serviceProvider) {
      setLoading(true)
      unsubscribe = firestoreListener({
        collection: 'purchasedProducts',
        where: [
          ['available', '==', true],
          ['product', '==', condition.product],
          [`serviceProvider.${condition.serviceProvider}`, '==', true]
        ],
        onData: ( data ) => {
          setResultData(data)
          setLoading(false)
        }
      })
    }

    if (['salesOrderTotalAmount', 'salesOrderPaymentAmount', 'salesOrderPurePaymentAmount'].includes(queryCondition.filter)) {
      setLoading(true)
      unsubscribe = firestoreListener({
        collection: 'salesOrders',
        where: [
          ['date', '>=', condition.startDate],
          ['date', '<=', condition.endDate],
          ['source', '==', condition.source]
        ],
        onData: (data) => {
          const mapping = {}
          for (const salesOrder of data) {
            if (salesOrder.void) continue

            const products = Object.keys(salesOrder.products).map(k => salesOrder.products[k])
            const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
            const collected = Object.keys(salesOrder.payments || {}).map(k => salesOrder.payments[k]).filter(p => !p.void).map(p => p.collected)
            const pureCollected = Object.keys(salesOrder.payments || {}).map(k => salesOrder.payments[k]).filter(p => !p.void && p.paymentType !== 'storedValue').map(p => p.collected)
              .reduce((acc, cur) => acc + cur, 0)
            if (total === collected) {
              salesOrder.status = 'fullPayment'
            } else if (collected === 0) {
              salesOrder.status = 'noPayment'
            } else {
              salesOrder.status = 'partialPayment'
            }
            salesOrder.uncollected = total - collected
            salesOrder.collected = collected
            salesOrder.paymentAmount = collected
            salesOrder.purePaymentAmount = pureCollected
            if (!mapping[salesOrder.customer]) {
              mapping[salesOrder.customer] = {
                soTotalAmount: 0,
                soPaymentAmount: 0,
                soPurePaymentAmount: 0,
                salesOrders: []
              }
            }
            mapping[salesOrder.customer].soTotalAmount += salesOrder.price
            mapping[salesOrder.customer].soPaymentAmount += salesOrder.paymentAmount
            mapping[salesOrder.customer].soPurePaymentAmount += salesOrder.purePaymentAmount
            mapping[salesOrder.customer].salesOrders.push(salesOrder)
          }

          let newData = Object.keys(mapping).map(k => ({
            id: k,
            customer: k,
            soTotalAmount: mapping[k].soTotalAmount,
            soPaymentAmount: mapping[k].soPaymentAmount,
            soPurePaymentAmount: mapping[k].soPurePaymentAmount,
            salesOrders: mapping[k].salesOrders,
          }))
          const v = condition.soTotalAmount.split('~')
          let max = 0;
          let min = 0;
          if (v.length === 1) {
            min = Number(v[0])
            max = Number(v[0])
          } else if (v.length === 2) {
            if (v[1] === '') {
              min = Number(v[0])
              max = -1
            } else {
              min = Math.min(Number(v[0]), Number(v[1]))
              max = Math.max(Number(v[0]), Number(v[1]))
            }
          }
          const conditionField = { salesOrderTotalAmount: 'soTotalAmount', salesOrderPaymentAmount: 'soPaymentAmount', salesOrderPurePaymentAmount: 'soPurePaymentAmount' }[queryCondition.filter]
          if (max === -1) {
            newData = newData.filter(i => i[conditionField] >= min)
          } else {
            if (min === max) {
              newData = newData.filter(i => i[conditionField] === min)
            } else {
              newData = newData.filter(i => i[conditionField] >= min &&  i[conditionField] <= max)
            }
          }
          // NEEDFIX: 此處有個問題, 就是如果該客戶從未在該公司有過營收單, 那應該不要被加進列表
          if (min === 0) {
            getSalesOrderHistory(condition.source).then(customerList => {
              for (const cId of customerList) {
                if (!mapping[cId]) {
                  newData.push({
                    id: cId,
                    customer: cId,
                    soTotalAmount: 0,
                    soPaymentAmount: 0,
                    soPurePaymentAmount: 0,
                    salesOrders: [],
                  })
                }
              }
              setResultData(newData)
              setLoading(false)
            })
          } else {
            setResultData(newData)
            setLoading(false)
          }
        }
      })
    }

    return () => {unsubscribe?.();}
  }, [queryCondition]);

  async function getSalesOrderHistory(source) {
    return new Promise((resolve, reject) => {
      if (soHistory[source]) {
        resolve(soHistory[source])
      } else {
        firestoreDoc({
          collection: 'salesOrderHistory',
          where: [['source', 'array-contains', source]],
          onData: (docs) => {
            const res = docs.reduce((acc, cur) => [...acc, cur.id], [])
            setSoHistory((history) => ({
              ...history,
              [source]: res
            }))
            resolve(res)
          },
        })
      }
    })
  }

  function showSalesOrder(key, field, data) {
    setSalesOrderInfo(data)
  }

  function updateQuery(field, value) {
    let newValue = value
    let newData = { ...query, [field.name]: newValue }
    setQuery(newData)
  }

  function onSelectProduct(product) {
    updateQuery({ name: 'product' }, product.id)
  }

  function updateQueryCondition() {
    setResultData([])
    if (currentFilter.name === 'product') {
      setQueryCondition( { filter: currentFilter.name, condition: JSON.stringify({ product: query.product, serviceProvider: currentCompany }) })
    } else if (['salesOrderTotalAmount', 'salesOrderPaymentAmount', 'salesOrderPurePaymentAmount'].includes(currentFilter.name)) {
      setQueryCondition( { filter: currentFilter.name, condition: JSON.stringify({ source: currentCompany, startDate, endDate, soTotalAmount: soTotalAmount }) })
    }
  }

  function updateSoTotalAmount(value) {
    setSoTotalAmount( data => {
      const newValue = value.replace(/[^0-9~]/g, '')

      // 有幾種格式 n, n~m, n~
      const v = newValue.trim().split('~')
      let err = false
      if (v.length === 1) {
        if (!(/^[0-9]+$/).test(v[0])) {
          err = true
        }
      } else if (v.length === 2) {
        const n = (/^[0-9]+$/).test(v[0])
        const m = (/^[0-9]+$/).test(v[1])
        if ((n && m) || (n && v[1] === '')) {
        } else {
          err = true
        }
      } else {
        err = true
      }
      if (err) {
        setSoTotalAmountError(formatMessage({ id: 'form.price.formatError' }))
      } else {
        setSoTotalAmountError('')
      }

      return newValue
    })
  }

  const allowQuery = () => {
    if (currentFilter.name === 'product') {
      return !!query.product
    } else if (['salesOrderTotalAmount', 'salesOrderPaymentAmount', 'salesOrderPurePaymentAmount'].includes(currentFilter.name)) {
      return soTotalAmount !== '' && !soTotalAmountError
    }
  }

  return (
    <div style={{ padding: '20px 24px 80px 24px' }}>
      {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      {salesOrderInfo && <SalesOrderInfoDiablog
        so={salesOrderInfo.salesOrder ? null : salesOrderInfo}
        salesOrderId={salesOrderInfo.salesOrder}
        highlightItems={salesOrderInfo.salesOrderItem ?  [salesOrderInfo.salesOrderItem] : []}
        userMapping={userMapping}
        customerMapping={customerMapping}
        sourceMapping={sourceMapping}
        onClose={() => setSalesOrderInfo(null)}
      />}
      {openDialog === 'product' && <SelectProductDialog
        multiSelect={false}
        headerCells={[{ text: 'name', sort: 'name' }]}
        rowCells={[{ field: 'name' }]}
        filterItems={[{ name: 'name' }]}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectProduct}
        items={products.filter(i => (i.seller || []).includes(currentCompany)) }
        onShelf
        dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
        tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
        // isMobile={isMobile}
        productMapping={productMapping}
      />}
      <Toolbar sx={{ pl: 2, pr: 1 }}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CompanyFilter userRight='salesOrder-view' />
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <FlexRow>
              <FilterMenu filterItems={filterItems} onFilterChanged={(f) => { setCurrentFilter(f) }}></FilterMenu>
              {currentFilter.name === 'product' &&
                <TextField
                  required
                  type="text"
                  size="small"
                  label={formatMessage({ id: 'salesOrder.query.product' })}
                  variant="outlined"
                  value={
                    query.product ? productMapping[query.product].name : ''
                  }
                  onClick={() => { setOpenDialog('product') }}
                  fullWidth
                />
              }
              {['salesOrderTotalAmount', 'salesOrderPaymentAmount', 'salesOrderPurePaymentAmount'].includes(currentFilter.name) && <>
                <DateRangePickerField
                  styles={{ flex: 'none' }}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChanged={setStartDate}
                  onEndDateChanged={setEndDate}
                />
                <TextField
                  required
                  type="text"
                  size="small"
                  sx={{ width: '240px' }}
                  onChange={(e) => updateSoTotalAmount(e.target.value)}
                  label={formatMessage({ id: `salesOrder.filter.${currentFilter.name}` })}
                  variant="outlined"
                  value={soTotalAmount}
                  error={soTotalAmountError ? true : false}
                  helperText={soTotalAmountError}
                />
              </>}
            </FlexRow>
          </Grid>
        </Grid>

        <EnhancedButton
          disabled={!allowQuery()}
          onClick={() => updateQueryCondition()}
          label={formatMessage({ id: 'button.query' })}
        />
      </Toolbar>
      {queryCondition.filter === 'product' && <QueryPPListTable showSalesOrder={showSalesOrder} userMapping={userMapping} customerMapping={customerMapping} data={resultData}></QueryPPListTable>}
      {['salesOrderTotalAmount', 'salesOrderPaymentAmount', 'salesOrderPurePaymentAmount'].includes(queryCondition.filter) &&
        <QuerySOTotalAmountTable
          showSalesOrder={showSalesOrder}
          userMapping={userMapping}
          customerMapping={customerMapping}
          data={resultData}
          mode={queryCondition.filter}
        ></QuerySOTotalAmountTable>}
    </div>
  );
}

export default SalesOrderQueryWrap;
