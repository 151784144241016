import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ExpandButton({ open, onExpandChange }) {
  return (
    <IconButton aria-label="expand row" size="small" onClick={e => onExpandChange(!open)}>
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
}

ExpandButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onExpandChange: PropTypes.func.isRequired,
};
