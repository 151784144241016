import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import { Upload } from '@mui/icons-material';

import EnhancedSwitch from 'components/EnhancedSwitch';
import EnhancedTable from 'components/EnhancedTable';
import ApplicationSent from 'components/ApplicationSent';
import ImageUpload from 'components/ImageUpload';
import DatePickerField from 'components/DatePickerField';
import AlertDialog from 'components/AlertDialog';
import ButtonProgress from 'components/ButtonProgress';
import { leaveTimeRangeCount, getVacationDate, getWeekDay } from 'modules/uitls';
import ContextStore from 'modules/context';
import { firestoreListener, callFunction } from 'modules/firebase';
import LeaveType from 'enum/LeaveType';

const _LeaveAllType = LeaveType.filter(type => type.type === 'all');
const _LeaveType = LeaveType;

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

function EditLeavePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const { leaveId, userId } = useParams()
  const navigate = useNavigate()
  const userMapping = useSelector(state => state.users.data);
  const [displayAll, setDisplayAll] = useState(false);
  const [page, setPage] = useState('edit');
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [leaveBalance, setLeaveBalance] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [timeDialogOpen, setTimeDialogOpen] = useState(false)
  const [leaveError, setLeaveError] = useState(false)
  const [pendingLeaves, setPendingLeaves] = useState([])
  const [currentWorkTime, setCurrentWorkTime] = useState({})
  const [punchClockException, setPunchClockException] = useState({})
  const [disabledSave, setDisabledSave] = useState(false)
  const [leaveData, setLeaveData] = useState({
    leaveType: '',
    startDate: dayjs(),
    startHour: '00',
    startMinute: '00',
    endDate: dayjs(),
    endHour: '00',
    endMinute: '00',
    leaveDay: 1,
    leaveHour: 0,
    newSubstitutes: '',
    reason: '',
    img: ''
  })

  const _currentUser = leaveId !== 'new' && currentUser.key !== userId ? { key: userMapping[userId]?.id, ...userMapping[userId] } : currentUser
  const substitutes = useSelector(state => state.users.ordered).filter(u => u.active && !u.developer && !u.endedAt && u.key !== _currentUser.key && _currentUser.department === u.department)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.leaveOvertimeWork.owner' }),
    }, {
      text: leaveId !== 'new' ? formatMessage({ id: 'leaves.editLeave' }) : formatMessage({ id: 'leaves.applyForLeave' }),
    }])
    return () => {
    };
  }, [leaveId]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'punchClockExceptions',
      onData: (data) => {
        const punchClockException = {}
        data.forEach(docData => {
          punchClockException[docData.date] = { ...docData }
        });
        setPunchClockException(punchClockException)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = leaveId !== 'new' ? firestoreListener({
      collection: 'leaves',
      doc: leaveId,
      onData: (data) => {
        const startTime = String(data.startTime).split(':')
        const endTime = String(data.endTime).split(':')

        setLeaveData({
          leaveType: data.type,
          startDate: dayjs(data.startDate),
          startHour: startTime[0],
          startMinute: startTime[1],
          endDate: dayjs(data.endDate),
          endHour: endTime[0],
          endMinute: endTime[1],
          leaveDay: data.days,
          leaveHour: data.hours,
          newSubstitutes: data.substitute,
          reason: data.reason,
          img: data.img,
          snapshot: data.snapshot
        })
      }
    }) : null

    return () => unsubscribe?.()
  }, [leaveId]);

  useEffect(() => {
    const unsubscribe = _currentUser.worktimeType === 'shift' ? firestoreListener({
      collection: 'shifts',
      where: [['staff', '==', _currentUser.key]],
      onData: (data) => {
        let newWt = {}
        data.forEach(s => {
          newWt[`${s.date}_${dayjs(s.date).format('ddd')}`] = {
            startTime: s.startTime,
            endTime: s.endTime,
            nightShift: s.nightShift
          }
        })

        newWt = Object.keys(newWt).reduce((acc, cur) => {
          if(newWt[cur].nightShift) {
            if(newWt[cur].endTime === '24:00') {
              const nextDate = dayjs(cur.substring(0, 10)).add(1, 'day')
              acc[cur] = {
                ...newWt[cur],
                endTime: newWt[`${nextDate.format('YYYY-MM-DD')}_${nextDate.format('ddd')}`].endTime
              }
            }
          } else {
            acc[cur] = { ...newWt[cur] }
          }
          
          return acc
        }, {})
        
        if (!newWt[`${dayjs().format('YYYY-MM-DD')}_${dayjs().format('ddd')}`]) {
          setLeaveError(true)
        }
        const wt = getWorkTime(leaveData.startDate, newWt)

        setCurrentWorkTime(newWt)
        if (leaveId === 'new') {
          const start = dayjs(`${dayjs(leaveData.startDate).format('YYYY-MM-DD')} ${wt.startHour}:${wt.startMinute}`)
          const end = dayjs(`${dayjs(leaveData.startDate).format('YYYY-MM-DD')} ${wt.endHour}:${wt.endMinute}`)
          const diffTime = end.diff(start, 'minutes')

          let hours = (diffTime - (diffTime % 60)) / 60
          let minute = diffTime % 60

          let timeData = {
            leaveHour: 0,
            leaveDay: 0
          }

          if (minute >= 30) {
            hours += 0.5
          }

          if (hours < 8) {
            if (hours >= 5) {
              hours -= 1
            }

            timeData.leaveHour = hours
          } else if (hours >= 8) {
            timeData.leaveDay += 1
          }

          setLeaveData(leave => ({ ...leave, ...wt, ...timeData }))
        }
      }
    }) : null

    return () => unsubscribe?.()
  }, []);

  useEffect(() => {
    const unsubscribe = _currentUser.worktimeType !== 'shift' ? firestoreListener({
      collection: 'worktimePolicies',
      doc: _currentUser.worktimePolicies,
      onData: (data) => {
        if (data) {
          const wt = getWorkTime(leaveData.startDate, data.worktime)
          setCurrentWorkTime(data.worktime)
          if (leaveId === 'new') {
            const start = dayjs(`${dayjs(leaveData.startDate).format('YYYY-MM-DD')} ${wt.startHour}:${wt.startMinute}`)
            const end = dayjs(`${dayjs(leaveData.startDate).format('YYYY-MM-DD')} ${wt.endHour}:${wt.endMinute}`)

            const diffTime = end.diff(start, 'minutes')

            let hours = (diffTime - (diffTime % 60)) / 60
            let minute = diffTime % 60

            let timeData = {
              leaveHour: 0,
              leaveDay: 0
            }

            if (minute >= 30) {
              hours += 0.5
            }

            if (hours < 8) {
              if (hours >= 5) {
                hours -= 1
              }

              timeData.leaveHour = hours
            } else if (hours >= 8) {
              timeData.leaveDay += 1
            }

            setLeaveData(leave => ({ ...leave, ...wt, ...timeData }))
          }
        }
      }
    }) : null

    return () => unsubscribe?.()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'leaveBalances',
      doc: _currentUser.key,
      onData: (data) => {
        setLeaveBalance(data)
      }
    })

    return () => unsubscribe()
  }, [_currentUser.key]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'leaves',
      where: [['status', '==', 'pending'], ['createdBy', '==', _currentUser.key]],
      onData: (data) => {
        setPendingLeaves(data)
      }
    })

    return () => unsubscribe()
  }, [_currentUser.key]);

  function getWorkTime(date, currentWorkTime) {
    const weekDay = getWeekDay(dayjs(date))
    const workT = _currentUser.worktimeType === 'shift' ?
      currentWorkTime[`${dayjs(date).format('YYYY-MM-DD')}_${weekDay}`] :
      currentWorkTime[weekDay]
    
    return {
      startHour: workT?.startTime.split(':')[0] ?? '00',
      startMinute: workT?.startTime.split(':')[1] ?? '00',
      endHour: workT?.endTime.split(':')[0] ?? '00',
      endMinute: workT?.endTime.split(':')[1] ?? '00',
      nightShift: workT?.nightShift ?? false
    }
  }

  const headerCells = [
    { text: 'type' },
    { text: 'earned' },
    { text: 'used' },
    { text: 'signing' },
    { text: 'voiding' },
    { text: 'balance' },
  ].map(c => { c.text = formatMessage({ id: `leaves.leaveForm.${c.text}` }); return c })

  const rowCells = [
    { field: 'name', textColor: true },
    { field: 'earned', textColor: true },
    { field: 'used', textColor: true },
    { field: 'signing', textColor: true },
    { field: 'voiding', textColor: true },
    { field: 'balance', textColor: true },
  ]

  function handleClose() {
    setDialogOpen(null)
    setTimeDialogOpen(null)
  }

  async function onLeaveEdit() {
    setLoadingApprove(true)
    const hours = (leaveData.leaveDay * 8) + leaveData.leaveHour
    let signs = 0

    if (leaveError) {
      return setLoadingApprove(false)
    }
    if (!leaveData.leaveType) {
      setLeaveData({ ...leaveData, leaveType_err: formatMessage({ id: 'form.field.isRequired' }) })
      return setLoadingApprove(false)
    }

    if (hours === 0) {
      setLoadingApprove(false)
      return setTimeDialogOpen(true)
    }

    if (leaveData.leaveType === 'vaccination_leave' && hours > 16) {
      setLoadingApprove(false)
      return setDialogOpen(true)
    }

    for (const s of pendingLeaves) {
      if (leaveData.leaveType === s.type && !s.void) {
        signs += (s.days * 8) + s.hours
      }
    }

    if (((leaveBalance[leaveData.leaveType].balance - signs) - hours) < 0 &&
      !['business_leave',
        'bereavement_leave',
        'publicinjury_leave',
        'epidemicPreventionCare_leave',
        'vaccination_leave',
        'quarantine_leave',
        'parental_leave',
      ].includes(leaveData.leaveType)) {
      setLoadingApprove(false)
      return setDialogOpen(true)
    }

    const newData = {
      type: leaveData.leaveType,
      startDate: dayjs(leaveData.startDate).format('YYYY-MM-DD'),
      startTime: `${leaveData.startHour}:${leaveData.startMinute}`,
      endDate: dayjs(leaveData.endDate).format('YYYY-MM-DD'),
      endTime: `${leaveData.endHour}:${leaveData.endMinute}`,
      reason: leaveData.reason,
      img: leaveData.img,
      days: leaveData.leaveDay,
      hours: leaveData.leaveHour,
      substitute: leaveData.newSubstitutes,
    }

    try {
      await callFunction('saveLeaves', { id: leaveId, ...newData })
      setLoadingApprove(false)
    } catch (ex) {
      console.log(ex)
    }

    setPage('done')
  }

  function handleDateChange(filed, date) {
    if (date === null || date.toString() === 'Invalid Date') {
      updateData(filed, '')
    } else {
      updateData(filed, date);
    }
  };

  function validateField(field, value) {
    if ((field.name === 'startDate' || field.name === 'endDate') && value === '') {
      return '無效的日期格式'
    }
    return ''
  }

  function updateData(field, value) {
    let newValue = value
    let err = validateField(field, value)
    let newData = { ...leaveData, [field.name]: newValue, [`${field.name}_err`]: err }
    const newWt =  getWorkTime(newData.startDate, currentWorkTime)
    const ns = newWt.nightShift ? true : false

    if (['startDate', 'endDate'].includes(field.name)) {
      if (field.name === 'startDate') {
        if(newWt.nightShift) {
          newData.endDate = dayjs(value).add(1, 'day')
        } else {
          newData.endDate = value
        }
      }

      newData = { ...newData, ...newWt }
    }

    if(!ns) {
      const leaveOfTime = leaveTimeRangeCount(newData, _currentUser.worktimeType, currentWorkTime, punchClockException, _currentUser)
      if (!leaveOfTime) {
        setLeaveError(true)
      } else {
        setLeaveError(false)
        newData.leaveDay = leaveOfTime.day
        newData.leaveHour = leaveOfTime.hour
      }
    } else {
      setLeaveError(false)
      const startDate = dayjs(newData.startDate).format('YYYY-MM-DD')
      const endDate = dayjs(newData.endDate).format('YYYY-MM-DD')
      const startTime = `${newData.startHour}:${newData.startMinute}`
      const endTime = `${newData.endHour}:${newData.endMinute}`
      const start = `${startDate} ${startTime}`;
      const end = `${endDate} ${endTime}`;
    
      const startD = dayjs(start, 'YYYY-MM-DD HH:mm');
      const endD = dayjs(end, 'YYYY-MM-DD HH:mm');
      let minutes = dayjs.duration(endD.diff(startD)).asMinutes()
      let minute = (minutes % 60)
      let hours = (minutes - (minutes % 60)) / 60
      let days = Number(dayjs(newData.endDate).startOf('date').diff(dayjs(newData.startDate).startOf('date'), 'day'))
    
      if (minute > 0) {
        if (minute <= 30) {
          hours += 0.5
        } else {
          hours += 1
        }
      }
      if (hours < 12) {
        newData.leaveDay = 0
        newData.leaveHour = hours
      } else if (hours >= 12) {
        // days += 1
        newData.leaveDay = days
        newData.leaveHour = 0
      }
    }

    if (field.name === 'img' && newValue !== '') {
      uploadImg(false)
    }

    setLeaveData(newData)
  }

  function formatData(value) {
    const newData = { ...value }
    newData.textColor = '#000'
    let newSigning = {
      days: 0,
      hours: 0,
      balance: 0
    }

    let newVoiding = {
      days: 0,
      hours: 0
    }

    for (const s of pendingLeaves) {
      if (s.type === newData.type) {
        if (s.void) {
          newVoiding.days += s.days
          newVoiding.hours += s.hours
        } else {
          newSigning.days += s.days
          newSigning.hours += s.hours
        }
      }
    }

    if (newData) {
      let used = newData.used
      let balance = newData.balance
      let sign = (newSigning.days * 8) + newSigning.hours
      if (newData.type === leaveData.leaveType) {
        used = newData.used + ((leaveData.leaveDay * 8) + leaveData.leaveHour)
        balance = newData.balance - ((leaveData.leaveDay * 8) + leaveData.leaveHour)

        if (leaveId !== 'new' && leaveData.snapshot) {
          if (newData.type === leaveData.snapshot.type) {
            used -= (leaveData.snapshot.days * 8) + leaveData.snapshot.hours
            balance += (leaveData.snapshot.days * 8) + leaveData.snapshot.hours
          }
        }

        if ((leaveData.leaveDay || leaveData.leaveHour) > 0) {
          newData.textColor = (balance - sign) >= 0 ? '#1976d2' : '#fe2851'
        }
      }

      if (newData.type === leaveData.leaveType &&
        ['business_leave',
          'bereavement_leave',
          'publicinjury_leave',
          'epidemicPreventionCare_leave',
          'vaccination_leave',
          'quarantine_leave',
          'parental_leave'
        ].includes(newData.type)) {
        balance = 0;
        if ((leaveData.leaveDay || leaveData.leaveHour) > 0) {
          newData.textColor = '#1976d2'
        }
      }


      let newEarned = newData.earned ? { days: (newData.earned - (newData.earned % 8)) / 8, hours: newData.earned % 8 } : { days: 0, hours: 0 };
      let newUsed = used ? { days: (used - (used % 8)) / 8, hours: used % 8 } : { days: 0, hours: 0 };
      let newBalance = newData.earned - used;

      let balanceCount = (balance - ((newSigning.days * 8) + newSigning.hours))

      if (['business_leave',
        'bereavement_leave',
        'publicinjury_leave',
        'epidemicPreventionCare_leave',
        'vaccination_leave',
        'quarantine_leave',
        'parental_leave'
      ].includes(newData.type)) {
        newBalance = { days: 0, hours: 0 };
      } else {
        newBalance = { days: (balanceCount - (balanceCount % 8)) / 8, hours: balanceCount % 8 };
      }

      newData.earned = `${newEarned.days}日${newEarned.hours}小時`
      newData.used = `${newUsed.days}日${newUsed.hours}小時`
      newData.balance = `${newBalance.days}日${newBalance.hours}小時`
      newData.signing = `${newSigning.days}日${newSigning.hours}小時`
      newData.voiding = `${newVoiding.days}日${newVoiding.hours}小時`
    }
    return newData
  }

  function uploadImg(type) {
    setDisabledSave(type)
  }

  return page === 'edit' ? (
    <div>
      {dialogOpen && <AlertDialog
        dialogTital={formatMessage({ id: 'leaves.leaveAlert.insufficientLeaveBalanceTitle' })}
        dialogMsg={
          leaveData.leaveType === 'vaccination_leave' ?
            formatMessage({ id: 'leaves.leaveAlert.vaccinationAlert' }) :
            formatMessage({ id: 'leaves.leaveAlert.insufficientLeaveBalanceContent' })}
        handleClose={handleClose}
      />}
      {timeDialogOpen && <AlertDialog
        dialogTital={formatMessage({ id: 'leaves.leaveAlert.leaveTimeAlertTitle' })}
        dialogMsg={formatMessage({ id: 'leaves.leaveAlert.leaveTimeAlertContent' })}
        handleClose={handleClose}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  select
                  type="text"
                  size="small"
                  label={formatMessage({ id: 'leaves.leaveForm.timeOffType' })}
                  variant="outlined"
                  required
                  error={leaveData['leaveType_err'] ? true : false}
                  helperText={leaveData.leaveType_err}
                  fullWidth
                  onChange={e => updateData({ name: 'leaveType' }, e.target.value)}
                  value={leaveData.leaveType}
                >
                  {_currentUser.gender !== 'male' ? _LeaveType.map((type, idx) => {
                    return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                  }) : _LeaveAllType.map((type, idx) => {
                    return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={formatMessage({ id: 'leaves.leaveForm.substitute' })}
                  value={leaveData.newSubstitutes}
                  onChange={e => updateData({ name: 'newSubstitutes' }, e.target.value)}
                >
                  {substitutes.map((user) => {
                    return <MenuItem key={user.id} value={user.id}>{user.displayName}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <DatePickerField
                  required
                  fullWidth
                  shouldDisableDate={(day) => getVacationDate(day.format('YYYY-MM-DD'), punchClockException, _currentUser.department, _currentUser.worktimeType, currentWorkTime)}
                  label={formatMessage({ id: 'leaves.leaveForm.startDateTime' })}
                  value={leaveData.startDate}
                  onChange={date => handleDateChange({ name: 'startDate' }, date)}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'leaves.leaveForm.leaveHour' })}
                  fullWidth
                  value={leaveData.startHour}
                  onChange={e => updateData({ name: 'startHour' }, e.target.value)}
                >
                  {hours.map((hour, idx) => {
                    return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'leaves.leaveForm.leaveMinute' })}
                  fullWidth
                  value={leaveData.startMinute}
                  onChange={e => updateData({ name: 'startMinute' }, e.target.value)}
                >
                  {minutes.map((minute, idx) => {
                    return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <DatePickerField
                  required
                  fullWidth
                  shouldDisableDate={(day) => getVacationDate(day.format('YYYY-MM-DD'), punchClockException, _currentUser.department, _currentUser.worktimeType, currentWorkTime)}
                  label={formatMessage({ id: 'leaves.leaveForm.endDateTime' })}
                  value={leaveData.endDate}
                  onChange={date => handleDateChange({ name: 'endDate' }, date)}
                  minDate={dayjs(leaveData.startDate).toDate()}
                  minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                // minDate={new Date()}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'leaves.leaveForm.leaveHour' })}
                  fullWidth
                  value={leaveData.endHour}
                  onChange={e => updateData({ name: 'endHour' }, e.target.value)}
                >
                  {hours.map((hour, idx) => {
                    return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'leaves.leaveForm.leaveMinute' })}
                  fullWidth
                  value={leaveData.endMinute}
                  onChange={e => updateData({ name: 'endMinute' }, e.target.value)}
                >
                  {minutes.map((minute, idx) => {

                    return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {!leaveError ?
                  <div style={{ display: 'flex' }}>
                    <div>{formatMessage({ id: 'leaves.leaveForm.leaveDays' })}</div>
                    <div>{leaveData.leaveDay}</div>
                    <div style={{ marginLeft: '10px' }}>{formatMessage({ id: 'leaves.leaveForm.leaveHours' })}</div>
                    <div>{leaveData.leaveHour}</div>
                  </div> :
                  <div style={{ color: 'red' }}>
                    {formatMessage({ id: 'form.date.leaveError' })}
                  </div>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  multiline
                  label={formatMessage({ id: 'leaves.leaveForm.reason' })}
                  value={leaveData.reason}
                  onChange={e => updateData({ name: 'reason' }, e.target.value)}
                >
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ p: 1, height: '100%' }} variant="outlined" onClick={() => uploadImg(true)}>
              <div style={{ color: '#00000088' }}>{formatMessage({ id: 'leaves.leaveForm.leavecertificate' })}</div>
              <ImageUpload
                currentImageUrl={leaveData.img}
                storagePath={`leavesImages/${_currentUser.key}/${dayjs().format('YYYY-MM-DD HH:MM')}`}
                onChange={(url) => updateData({ name: 'img' }, url)}
              />
            </Card>
          </Grid>
        </Grid>
        <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 500 }} component="div">
            <FormattedMessage id="leaves.leaveForm.currentBalances" />:
          </Typography>

          <Typography component="div" style={{ height: '100%' }}>
            <EnhancedSwitch checked={displayAll} onChange={e => setDisplayAll(!displayAll)} rightLabel="顯示所有假別" />
          </Typography>

        </div>
        <EnhancedTable
          size="small"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={
            (_currentUser.gender === 'male' ? _LeaveAllType : _LeaveType)
              .filter(type => displayAll || ['annual_leave', 'sick_leave', 'personal_leave'].includes(type.value) || type.value === leaveData.leaveType)
              .map(type => ({ id: type.value, name: type.name, ...formatData({ type: type.value, ...leaveBalance[type.value] }) }))
          }
        />
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <ButtonProgress
            handleClick={() => onLeaveEdit()}
            handleClose={() => navigate('/leaveOvertimeWork/leaves')}
            loading={loadingApprove}
            buttonText={'button.submit'}
            disabledField={disabledSave || leaveError}
          />
        </Stack>
      </Box>
    </div>
  ) : (
    <ApplicationSent
      title={'leaveSent.leaveApplicationSent'}
      message={'leaveSent.sentMessage'}
      buttonText={'leaveSent.newBack'}
      buttonClick={'/leaveOvertimeWork/leaves'}
    />
  );
}

export default EditLeavePage;
