const i18n = {
  'shoppingCart': {
    'table': {
      'header': {
        'requisitionSn': '請購單號',
        'code': '品號',
        'name': '品名',
        'nickname': '簡稱',
        'amount': '數量',
        'inventory': '即時庫存',
        'sku': '庫存單位(SKU)',
        'orderUnit': '訂貨單位',
        'stock': '即時庫存',
        'expectedDate': '需求日期',
        'supplier': '供應商',
      }
    },
    'removeMerchandise': '移除商品',
    'moveToPurchaseOrder': '開始建立採購單',
  },
  'shoppingCartList': {
    'empty': '採購清單是空的',
  }
}
export default i18n
