const i18n = {
  'receipt': {
    'tab': {
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'name': '進貨單',
    'table': {
      'pending': {
        'title': '進貨單列表 - 待確認',
      },
      'title': '進貨單列表',
      'selected': '已選擇 {count} 筆進貨單',
      'header': {
        'sn': '進貨單號',
        'supplier': '供應商',
        'supplierName': '供應商',
        'source': '進貨單位',
        'sourceName': '進貨單位',
        'createdBy': '進貨人',
        'createdAt': '建立日期',
        'date': '進貨日期',
      },
      'detail': {
        'sn': '進貨單號',
        'source': '進貨單位',
        'sourceName': '進貨單位',
        'createdBy': '進貨人',
        'createdAt': '建立日期',
        'date': '進貨日期',
        'note': '備註',
        'merchandise': '商品',
        'invoiceNumber': '發票號碼',
        'discount': '現金折扣',
        'shippingFee': '運費',
        'shippingOut': '出貨廠商',
        'supplier': '供應商',
        'supplierName': '供應商',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
        'tax': '營業稅',
      },
    },
    'print': {
      'title': '進貨單',
      'sn': '進貨單號',
      'createdBy': '進貨人',
      'date': '進貨日期',
      'invoiceNumber': '發票號碼',
      'note': '備註',
      'merchandise': {
        'extra': '贈品',
        'purchaseOrderSn': '採購單號',
        'code': '品號',
        'name': '品名',
        'require': '需求量',
        'unitPrice': '單價',
        'amount': '到貨數量',
        'returnAmount': '退貨數量',
        'orderUnit': '單位',
        'price': '金額',
        'lotNumber': '批號',
        'expectedDate': '效期',
        'statusText': '狀態',
        'discount': '折扣',
        'note': '備註',
      }
    },
    'dialog': {
      'title': {
        'add': '新增進貨單',
        'edit': '編輯進貨單',
      }
    },
    'merchandise': {
      'extra': '贈品',
      'purchaseOrderSn': '採購單號',
      'code': '品號',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'amount': '到貨數量',
      'returnAmount': '退貨數量',
      'orderUnit': '單位',
      'sku': '單位',
      'price': '金額',
      'lotNumber': '批號',
      'expectedDate': '效期',
      'wareroom': '倉庫',
      'statusText': '狀態',
      'discount': '商品折扣',
      'note': '備註',
    }
  },
  'editReceipt': {
    'table': {
      'title': '商品清單',
      'title2': '贈品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'addFromPurchaseOrder': '核對採購單',
    'addFromBorrowingOrder': '核對借貨單',
    'addExtraMerchandise': '新增贈品',
    'addLotNumber': '新增批號',
    'removeMerchandise': '移除商品',
    'addExtraItemsLotNumber': '新增贈品批號',
    'removeExtraMerchandise': '移除贈品',
  }
}
export default i18n
