import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import EnhancedButton from 'components/EnhancedButton';
import EnhancedTable from 'components/EnhancedTable';
import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import ButtonContainer from 'components/ButtonContainer';
import { decodeHistoryLog } from 'modules/historyLog';
import { callFunction, documentId, firestoreListener } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

function OvertimeView({ currentUser, overtime, isHistory, isAll, type }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.users.data);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [overtimeBalance, setOvertimeBalance] = useState([]);
  const [pendingOvertimes, setPendingOvertimes] = useState([]);
  const [punchClock, setPunchClock] = useState([]);
  // const [shiftMapping, setShiftMapping] = useState({});

  const overtimeHistory = (overtime.history || []).map(h => ({ ...h }))
  const currentStep = overtimeHistory.length > 0 ? overtimeHistory[overtimeHistory.length - 1].step : 0
  const queryKey = type === 'review' ? overtime.createdBy : currentUser.key

  // useEffect(() => {
  //   const unsubscribe = firestoreListener({
  //     collection: 'shifts',
  //     where: [['staff', '==', queryKey]],
  //     onData: (data) => {
  //       let newData = {}
  //       data.forEach(s => {
  //         newData[s.date] = { ...s }
  //       })

  //       setShiftMapping(newData)
  //     }
  //   })

  //   return () => unsubscribe()
  // }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'punchClock',
      where: [[documentId(), '==', overtime.startDate]],
      onData: (data) => {
        let punchClock = []
        data.forEach(docData => {
          if (docData.record && docData.record[overtime.createdBy]) {
            punchClock = punchClock.concat(docData.record[overtime.createdBy])
          }

          if (docData.revise && docData.revise[overtime.createdBy]) {
            punchClock = punchClock.concat(docData.revise[overtime.createdBy])
          }
        });

        setPunchClock(punchClock)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('overtimeBalances').doc(queryKey).onSnapshot(snapshot => {
      setOvertimeBalance(snapshot.data())
    }, err => { })

    return () => unsubscribe()
  }, [queryKey]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('overtimes')
      .where('status', '==', 'pending')
      .where('createdBy', '==', queryKey)
      .onSnapshot(snapshot => {
        let snapshots = []
        snapshot.forEach(doc => {
          snapshots.push({ id: doc.id, ...doc.data() })
        })
        setPendingOvertimes(snapshots)
      }, err => { })

    return () => unsubscribe()
  }, [queryKey]);

  const headerCells = [
    { text: 'periods' },
    { text: 'totalOvertime' },
    { text: 'signPay' },
    { text: 'pay' },
    { text: 'signLeave' },
    { text: 'leave' },
    { text: 'allowance' },
  ].map(c => { c.text = formatMessage({ id: `overtimes.overtimeForm.${c.text}` }); return c })

  const rowCells = [
    { field: 'periods' },
    { field: 'totalOvertime' },
    { field: 'signPay' },
    { field: 'pay' },
    { field: 'signLeave' },
    { field: 'leave' },
    { field: 'allowance' },
  ]

  function decodeModifyLog(log) {
    const fmMapping = {
      modify: 'step.action.modify',
      update: 'step.action.update',
      add: 'step.action.add',
      remove: 'step.action.remove',

      startTime: 'overtimes.table.start',
      startDate: 'overtimes.overtimeForm.startDateTime',
      endTime: 'overtimes.table.end',
      endDate: 'overtimes.overtimeForm.endDateTime',
      type: 'overtimes.table.type',
      reason: 'overtimes.overtimeForm.reason',
    }
    return decodeHistoryLog(log, [
      { key: 'f', replacer: s => formatMessage({ id: fmMapping[s] }) },
      { key: 'i', replacer: s => formatMessage({ id: `overtimeType.${s}` }) },
    ])
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve'].includes(action)) {
      setLoadingApprove(true)
    } else {
      setLoadingReject(true)
    }

    try {
      await callFunction('reviewOvertimes', {
        id: overtime.id,
        action,
        note: text
      })

      if (['approve'].includes(action)) {
        if (currentStep < overtime.steps.length - 1) {
          setLoadingApprove(false)
        }
      } else {
        setLoadingReject(false)
      }
    } catch (ex) {
      setLoadingApprove(false)
      setLoadingReject(false)
      console.log(ex)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  const ls = overtime.status !== 'void' ? overtime.steps.slice(currentStep, overtime.steps.length).map(s => ({ ...s })) : []
  if (overtime.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...overtimeHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    } else if (['approve'].includes(step.action)) {
      step.labelProps = { color: 'green', success: true }
    }
  }

  function getDisabled() {
    const nPunchClock = punchClock.sort()
    const pt = dayjs(`${overtime.startDate} ${nPunchClock[nPunchClock.length - 1]}`)
    const ot = dayjs(`${overtime.startDate} ${overtime.startTime}`)
    const m = pt.diff(ot, 'minutes')

    if(loadingApprove || loadingReject) {
      return true
    }

    if(overtime.startDate === overtime.endDate) {
      if (punchClock.length === 0) {
        return true
      } else if (pt.isSameOrBefore(ot)) {
        return true
      } else if (m < 30) {
        return true
      }
    } else {
      if (punchClock.length === 0) {
        return true
      }
    }

    return false
  }

  function timeRangeToPeriods() {
    const startDate = dayjs(overtime.startDate).format('YYYY-MM-DD')
    const startTime = overtime.startTime
    const endDate = dayjs(overtime.endDate).format('YYYY-MM-DD')
    const endTime = overtime.endTime
    const start = `${startDate} ${startTime}`;
    const end = `${endDate} ${endTime}`;
    const reg = /\d+-\d+-\d+ \d+:\d+/;

    if (reg.test(start) && reg.test(end)) {
      const startM = dayjs(start, 'YYYY-MM-DD HH:mm');
      const endM = dayjs(end, 'YYYY-MM-DD HH:mm');
      dayjs.extend(duration)
      const minutes = dayjs.duration(endM.diff(startM)).asMinutes();
      var periods = 0;
      if (minutes >= 30) {
        periods = Math.floor(Math.floor(minutes / 15) / 2.0);
      }
      return periods;
    }
  }

  const hoursToPeriods = timeRangeToPeriods();

  const BalanceRow = () => {
    if (overtimeBalance.pay === undefined || overtimeBalance.leave === undefined) return null;
    let signing = {
      pay: 0,
      leave: 0,
      shift: 0
    }
    for (const s of pendingOvertimes) {
      signing[s.type.replace('overtime_', '')] += s.hours
    }

    let pay = overtimeBalance.pay.balance * 2
    let leave = overtimeBalance.leave.balance * 2
    let shift = overtimeBalance.shift.balance
    let signPay = signing.pay * 2
    let signLeave = signing.leave * 2
    let periods = parseFloat(hoursToPeriods);
    let overtime = pay + leave + signPay + signLeave;

    if (overtime && !['approved', 'rejected'].includes(overtime.status)) {
      if (overtime.overtimeType === 'overtime_pay') {
        pay = `${pay + signPay + periods}`;
      } else if (overtime.overtimeType === 'overtime_leave') {
        leave = `${leave + signLeave + periods}`;
      } else if (overtime.overtimeType === 'overtime_shift') {
        let shiftperiods = 1
        shift = `${shift + signing.shift + shiftperiods}`;
        leave = `${leave + signLeave + periods}`;
      }
    }

    const data = [{
      id: 'overtime',
      periods: hoursToPeriods,
      totalOvertime: overtime,
      signPay,
      pay,
      signLeave,
      leave,
      allowance: shift
    }]

    return (
      <EnhancedTable
        size="small"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data}
      />
    )
  };

  return (
    <div style={{ width: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={dialogData.action === 'reject' ? formatMessage({ id: 'overtimes.detail.rejectReason' }) : formatMessage({ id: 'overtimes.detail.note' })}
        action={dialogData.action}
      />}
      <Box p={0}>
        <ProgressStep
          activeStep={overtime.history.length}
          steps={steps}
          title={formatMessage({ id: 'overtimes.list.step' })}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'leaves.leaveForm.reason' })}
              value={overtime.reason}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography sx={{ fontWeight: 500 }} component="div">
              <FormattedMessage id="overtimes.list.balance" />:
            </Typography>
            <BalanceRow />
          </Grid>
          {type === 'review' && !isHistory && !isAll && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              <EnhancedButton
                color="error"
                onClick={() => setDialogData({ action: 'reject', title: '' })}
                disabled={loadingApprove || loadingReject}
                progress={loadingReject}
                label={formatMessage({ id: 'button.reject' })}
              />
              <EnhancedButton
                color="success"
                onClick={() => setDialogData({ action: 'approve', title: '' })}
                disabled={getDisabled()}
                progress={loadingApprove}
                label={formatMessage({ id: 'button.approve' })}
              />
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

OvertimeView.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  isAll: PropTypes.bool,
  isHistory: PropTypes.bool,
  overtime: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default OvertimeView;
