import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const Status = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '12px',
  minWidth: '12px',
  height: '12px',
  borderRadius: '12px',
  marginRight: '4px',
  display: 'inline-flex'
}));

const colorMapping = {
  'not-confirmed': {
    background: '#c641e2'
  },
  'profile-needed': {
    background: '#2a9bf2'
  },
  ready: {
    border: 'solid 2px #000000'
  },
  'not-arrived': {
    border: 'solid 2px #000000'
  },
  'treatment-confirmed': {
    border: 'solid 2px #000000'
  },
  arrived: {
    background: '#ffbf38'
  },
  late: {
    background: '#fe2851'
  },
  cancelled: {
    background: '#fe2851'
  },
  complete: {
    background: '#10d09e',
  },
  cancelanotherappointment: {
    background: '#d4e20c'
  }
}

function StatusIndicator({ status }) {
  const { formatMessage } = useIntl()
  const newStatus = status || 'not-confirmed'

  return (
    <Tooltip title={formatMessage({ id: `appointment.popover.customerStatus.${newStatus}` })}>
      <Status style={colorMapping[newStatus]} />
    </Tooltip>
  );
}

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusIndicator;
