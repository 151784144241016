import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableEx from 'components/EnhancedTableEx';

function SalesOrderList({ salesOrders, showSalesOrder, userMapping, mode }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'agent' },
    { text: 'price' },
    { text:  mode === 'salesOrderPurePaymentAmount' ? 'purePaymentAmount' : 'paymentAmount' },
    { text: 'status' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn', type: 'info', onButtonClick: showSalesOrder },
    { field: 'agentName', textColor: true },
    { field: 'price', textColor: true },
    { field: mode === 'salesOrderPurePaymentAmount' ? 'purePaymentAmount' : 'paymentAmount', textColor: true },
    { field: 'statusText', textColor: true },
  ]

  function formatData(salesOrder) {
    const newData = { ...salesOrder }

    newData.statusText = formatMessage({ id: `salesOrder.status.${newData.status}` })
    newData.agentName = userMapping[newData.agent]?.displayName || ''

    if (newData.void) {
      newData.textColor = '#bab7b7'
    }

    return newData
  }

  return <EnhancedTable
    size="small"
    defaultOrder="asc"
    defaultOrderField="sn"
    headerCells={headerCells}
    rowCells={rowCells}
    tableData={salesOrders.map(i => formatData(i))}
  />
}

function QuerySOTotalAmountTable({ showSalesOrder, userMapping, customerMapping, data, mode }) {
  const { formatMessage } = useIntl()
  const tableRef = useRef()

  const headerCells = [
    { text: 'customer' },
    { text: mode },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'customerName' },
    { field:  { salesOrderTotalAmount: 'soTotalAmount', salesOrderPaymentAmount: 'soPaymentAmount', salesOrderPurePaymentAmount: 'soPurePaymentAmount' }[mode] },
  ]

  function formatData(pp) {
    const newData = { ...pp }
    newData.customerName = customerMapping[newData.customer]?.name || ''
    return newData
  }

  function exportTable() {
    tableRef.current.saveToFile(`SalesQuery_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`)
  }

  return  (data.length ?
    <EnhancedTableEx
      ref={tableRef}
      defaultOrder="asc"
      defaultOrderField="soTotalAmount"
      headerCells={headerCells}
      rowCells={rowCells}
      getHeaderActionIcons={() =>
        <IconButton
          onClick={() => exportTable()}
          size="large">
          <GetAppIcon></GetAppIcon>
          <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
        </IconButton>
      }
      getExpandContent={data =>
        <SalesOrderList
          salesOrders={data.salesOrders}
          showSalesOrder={showSalesOrder}
          userMapping={userMapping}
          mode={mode}
        />
      }
      tableData={data.map(i => formatData(i))}
    />
    : <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
          查無資料
    </Typography>)
}

export default QuerySOTotalAmountTable;
