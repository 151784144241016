import dayjs from 'dayjs';

export default class PunchClock {
  constructor(date, punchClockNormal, punchClockRevise) {
    let punchClockMix;
    if (punchClockNormal || punchClockRevise) {
      punchClockMix = [];
      if (punchClockNormal) punchClockMix = punchClockMix.concat(punchClockNormal);
      if (punchClockRevise) punchClockMix = punchClockMix.concat(punchClockRevise);
      punchClockMix = Array.from(new Set(punchClockMix.sort((a, b) => {
        const timeAM = dayjs(date.format('YYYY-MM-DD') + ' ' + a, 'YYYY-MM-DD HH:mm');
        const timeBM = dayjs(date.format('YYYY-MM-DD') + ' ' + b, 'YYYY-MM-DD HH:mm');
        if (timeAM.isBefore(timeBM)) {
          return -1;
        } else if (timeAM.isBefore(timeBM)) {
          return 1;
        } else {
          return 0;
        }
      })));
    }
    this.all = punchClockMix;
    this.isExist = !!punchClockNormal;

    if (punchClockMix) {
      this.list = punchClockMix.map((time) => {
        if (punchClockRevise && punchClockRevise.includes(time)) {
          return { time: time, revised: true }
        } else {
          return { time: time, revised: false }
        }
      })
    }
    return this;
  }

  get length() {
    return this.all ? this.all.length : 0;
  }

  get isEmpty() {
    return this.length === 0;
  }

  get first() {
    return this.all[0];
  }

  get last() {
    return this.all[this.length - 1];
  }

}
