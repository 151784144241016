import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExportIcon from '@mui/icons-material/FileDownload';
import ImportIcon from '@mui/icons-material/FileUpload';
import CircularProgressMui from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';

import ButtonProgress from 'components/ButtonProgress';
import DatePickerField from 'components/DatePickerField';
import { exportFields, objectToArray } from 'modules/data';
import { datesData, getWeekDay } from 'modules/uitls';
import { documentId, callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import { useDepartments } from 'hooks/departments';

const CircularProgress = styled(CircularProgressMui)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LeaveTypeMap = {
  weekly_leave: '本休',
  sick_leave: '病假',
  annual_leave: '特休',
  menstrual_leave: '生理假',
  personal_leave: '事假',
  bereavement_leave: '喪假',
  marriage_leave: '婚假',
  maternity_leave: '產假',
  reproductive_leave: '產檢假',
  paternity_leave: '陪產假',
  fetal_leave: '安胎假',
  parental_leave: '育嬰假',
  family_leave: '家庭照顧假',
  publicinjury_leave: '公傷假',
  overtime_leave: '補休假',
  business_leave: '公假',
  epidemicPreventionCare_leave: '防疫照顧假',
  vaccination_leave: '疫苗接種假',
  quarantine_leave: '防疫隔離假'
}

const RepeatMap = {
  even_weeks: '雙週',
  every_week: '每週',
  no: '特約',
  odd_weeks: '單週',
  next_weeks: '隔週',
  leaves: '休診',
  zhongli: '中離',
}

function ScheduleExportDialog({ handleClose }) {
  const { formatMessage } = useIntl()
  const [loading, setloading] = useState(false)
  const users = useSelector(state => state.users.ordered).filter(u => !u.developer)
  const [data, setData] = useState({ date: dayjs().format('YYYY-MM'), departments: [] })
  const departments = useDepartments()

  const depMapping = departments.reduce((acc, cur) => { acc[cur.id] = { ...cur }; return acc }, {})
  const [punchClock, setPunchClock] = useState({})
  const [leaves, setLeaves] = useState([])
  const [shifts, setShifts] = useState([])
  const [weeklyLeaves, setWeeklyLeaves] = useState([])
  const [worktimePolicyMapping, setWorktimePolicyMapping] = useState({})
  const [punchClockRevise, setPunchClockRevise] = useState(null)
  const [doctorShift, setDoctorShift] = useState({})
  const [doctorSpecial, setDoctorSpecial] = useState({})
  const [doctorLeave, setDoctorLeave] = useState({})

  const dates = datesData(dayjs(data.date).format('YYYY'), dayjs(data.date).format('MM'))
  const startDate = `${dayjs(data.date).format('YYYY-MM')}-01`;
  const endDate = `${dayjs(data.date).format('YYYY-MM')}-${dayjs(data.date).daysInMonth()}`
  const yearMonthStr = dayjs(data.date).format('YYYYMM')

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('worktimePolicies').onSnapshot(snapshot => {
      let policies = {}
      snapshot.forEach(doc => {
        policies[doc.id] = { id: doc.id, ...doc.data() }
      })
      setWorktimePolicyMapping(policies)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('shifts')
      .where('date', '>=', startDate).where('date', '<=', endDate)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setShifts(data)
      }, err => { })

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDate, endDate]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('weeklyLeaves')
      .where('date', '>=', startDate).where('date', '<=', endDate)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setWeeklyLeaves(data)
      }, err => { })

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDate, endDate]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('leaveList')
      .where('status', '==', 'done')
      .where('monthIndex', 'array-contains', yearMonthStr)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setLeaves(data)
      }, err => { })

    return () => { if (unsubscribe) unsubscribe() }
  }, [yearMonthStr]);


  useEffect(() => {
    const unsubscribe = firebaseV8().collection('doctorShifts').where('repeats', '!=', 'no')
      .onSnapshot(snapshot => {
        const shifts = {}
        snapshot.forEach(doc => {
          shifts[doc.id] = { ...doc.data() };
        })

        setDoctorShift(shifts)

      }, err => { })

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDate, endDate]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('doctorShifts')
      .where('firstDate', '>=', startDate).where('firstDate', '<=', endDate).where('repeats', '==', 'no')
      .onSnapshot(snapshot => {
        const shifts = {}
        snapshot.forEach(doc => {
          shifts[doc.id] = { ...doc.data() };
        })

        setDoctorSpecial(shifts)
      }, err => { })

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDate, endDate]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('doctorLeaves')
      .where('firstDate', '>=', startDate).where('firstDate', '<=', endDate)
      .onSnapshot(snapshot => {
        const snapshots = {}
        snapshot.forEach(doc => {
          snapshots[doc.id] = { ...doc.data() };
        })
        setDoctorLeave(snapshots)
      }, err => { })

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDate, endDate]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('punchClock')
      .where(documentId(), '>=', startDate).where(documentId(), '<=', endDate)
      .onSnapshot(snapshot => {
        const data = {}
        const reviseData = {}
        snapshot.forEach(doc => {
          data[doc.id] = { ...doc.data().record }
          reviseData[doc.id] = { ...doc.data().revise }
        });

        setPunchClock(data)
        setPunchClockRevise(reviseData)
      }, err => { })
    return () => unsubscribe()
  }, [startDate, endDate]);

  function updateData(name, value) {
    const newData = { ...data }
    newData[name] = value

    setData(newData)
  }

  const fields = [
    { name: 'date', type: 'time', required: true },
    { name: 'departments', type: 'select-check', required: true }
  ].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field) {
    let labelText = '';
    let newValue = data[field.name]

    if (field.type === 'time') {
      labelText = formatMessage({ id: `schedule.export.table.${field.name}` })
      return <Grid item key={field.name} xs={12} sm={3} md={3} sx={{ ml: '20px' }}>
        <DatePickerField
          fullWidth
          margin="dense"
          required={field.required}
          label={labelText}
          inputFormat='YYYY-MM'
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData(field.name, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'select-check') {
      labelText = formatMessage({ id: `schedule.export.table.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={8} md={8}>
        <FormControl size="small"
          fullWidth
          required={field.required}
          error={data[`${field.name}_err`] ? true : false}
        >
          <InputLabel id="demo-multiple-checkbox-label">{labelText}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={data.departments}
            onChange={e => { updateData(field.name, e.target.value) }}
            input={<OutlinedInput label={labelText} />}
            renderValue={(selected) => selected.map(s => depMapping[s].name).join(', ')}
            MenuProps={MenuProps}
          >
            {departments.map((dep) => {
              if (data.departments.length === 0) {
                return < MenuItem key={dep.id} value={dep.id} >
                  <Checkbox checked={data.departments.includes(dep.id)} />
                  <ListItemText primary={dep.name} />
                </MenuItem>
              } else {
                if (data.departments.includes('doctor')) {
                  return < MenuItem disabled={dep.id !== 'doctor'} key={dep.id} value={dep.id} >
                    <Checkbox checked={data.departments.includes(dep.id)} />
                    <ListItemText primary={dep.name} />
                  </MenuItem>
                } else {
                  return < MenuItem disabled={dep.id === 'doctor'} key={dep.id} value={dep.id} >
                    <Checkbox checked={data.departments.includes(dep.id)} />
                    <ListItemText primary={dep.name} />
                  </MenuItem>
                }
              }
            })}
          </Select>
          <FormHelperText id="component-error-text">{data[`${field.name}_err`]}</FormHelperText>
        </FormControl>
      </Grid >
    }
  }

  function formatDate(date) {
    if (date < '12:00') {
      return true
    } else {
      return false
    }
  }

  function getShiftByDate(date, doctor) {
    const startDate = dayjs(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD');
    const endDate = dayjs(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');

    const scope = objectToArray(doctorShift).filter(shift => {
      if (shift.lastDate) {
        return shift.lastDate >= startDate;
      } else {
        return shift.firstDate <= endDate;
      }
    })

    const scopeSpecial = objectToArray(doctorSpecial).filter(shift => {
      if (shift.lastDate) {
        return shift.lastDate >= startDate;
      } else {
        return shift.firstDate <= endDate;
      }
    })

    const shifts = scope.concat(scopeSpecial).reduce((acc, shift) => {
      const { repeats } = shift
      let dateM = dayjs(shift.firstDate);

      if (shift.doctor === doctor) {
        if (repeats !== 'no') {
          acc[shift.firstDate] = { ...acc[shift.firstDate], [shift.id]: shift }
          while (dateM.isSameOrBefore(dayjs(shift.lastDate || endDate, 'YYYY-MM-DD'))) {
            dateM = dateM.add(1, 'week')
            const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1
            if (repeats === 'every_week' ||
              (repeats === 'even_weeks' && weeks % 2 === 0) ||
              (repeats === 'odd_weeks' && weeks % 2 === 1)) {
              acc[dateM.format('YYYY-MM-DD')] = { ...acc[dateM.format('YYYY-MM-DD')], [shift.id]: shift }
            }
          }
        } else {
          if (acc[shift.firstDate] && acc[shift.firstDate].repeats !== 'no') {
            acc[`_${shift.firstDate}`] = { ...acc[`_${shift.firstDate}`], [shift.id]: shift }
          } else {
            acc[shift.firstDate] = { ...acc[shift.firstDate], [shift.id]: shift }
          }
        }
      }
      return acc;
    }, {});

    return shifts
  }

  function onExportField() {
    setloading(true)
    let err = {}
    let currentData = data

    for (let field of fields) {
      if (field.required && (data[field.name] === '' || data[field.name].length === 0)) {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      currentData = { ...data, ...err }
    }
    if (Object.keys(err).length > 0) {
      setData(currentData)
      return setloading(false)
    }

    const path = 'Schedule_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'

    const newData = []
    for (const dep of data.departments) {
      users.forEach(user => {
        if (user.department === dep) {
          let newPunchClock = {}
          let newPunchClockRevise = {}

          for (const pc in punchClock) {
            if (punchClock[pc][user.id]) {
              newPunchClock[pc] = punchClock[pc][user.id]
            }
          }

          for (const pc in punchClockRevise) {
            if (punchClockRevise[pc][user.id]) {
              newPunchClockRevise[pc] = punchClockRevise[pc][user.id]
            }
          }

          if (user.active) {
            if (user.department === 'doctor') {
              newData.push({
                ...user,
                leaves: [],
                shifts: [],
                punchClock: newPunchClock,
                punchClockRevise: newPunchClockRevise
              })
            } else {
              newData.push({
                ...user,
                punchClock: newPunchClock,
                leaves: leaves.filter(l => l.createdBy === user.id).concat(weeklyLeaves.filter(w => w.staff === user.id)),
                shifts: shifts.filter(s => s.staff === user.id),
                punchClockRevise: newPunchClockRevise
              })
            }
          }
        }
      })
    }

    let shiftCount = 0
    let leaveCount = 0;
    newData.forEach(data => {
      dates.forEach(d => {
        const date = dayjs(d).format('YYYY-MM-DD')
        const filterLeaves = data.leaves.filter(leave => leave.startDate === date || leave.date === date)
        if (filterLeaves.length > leaveCount) {
          leaveCount = filterLeaves.length
        }
        let dateShiftMappingCache = getShiftByDate(date, data.id);
        if (dateShiftMappingCache[date] && (Object.keys(dateShiftMappingCache[date]).length > shiftCount)) {
          shiftCount = Object.keys(dateShiftMappingCache[date]).length
        }
      })
    })

    const rows = []
    const currentRow = ['日期', '姓名', '排班上班', '排班下班', '打卡上班', '打卡下班', '出勤時數', '補登上班', '補登下班'];
    const _rows = [];
    const _currentRow = ['日期', '姓名', '請假類型', '開始時間', '結束時間', '補登上班', '補登下班', '打卡上班', '打卡下班', '出勤時數'];

    for (let i = 0; i < leaveCount; i++) {
      currentRow.push('請假類型')
      currentRow.push('開始時間')
      currentRow.push('結束時間')
    }

    for (let i = 0; i < shiftCount; i++) {
      _currentRow.push('排班類型')
      _currentRow.push('排班上班')
      _currentRow.push('排班下班')
    }

    rows.push(currentRow.join(','))
    _rows.push(_currentRow.join(','))

    newData.forEach(data => {
      let totalPunchTime = 0
      dates.forEach(d => {
        const weekday = getWeekDay(dayjs(d))
        const date = dayjs(d).format('YYYY-MM-DD')
        let leaveMapping = {};
        let shiftMapping = {};
        let leaveCounts = [];
        let shiftCounts = null;
        let dateShiftMappingCache = getShiftByDate(date, data.id);
        let dateLeaveMappingCache = {}
        for (const l of Object.keys(doctorLeave)) {
          if (doctorLeave[l].doctor === data.id) {
            dateLeaveMappingCache[data.id] = { [doctorLeave[l].firstDate]: { ...doctorLeave[l] } }
          }
        }

        for (const shift of data.shifts) {
          if (shift.date === date) {
            shiftCounts = shift
          }
        }

        for (const leave of data.leaves) {
          if ((leave.startDate === date || leave.endDate === date)) {
            leaveCounts.push({ ...leave })
          } else if (!leave.type && leave.date === date) {
            leaveCounts.push({ ...leave })
          }
        }

        leaveMapping[date] = leaveCounts
        shiftMapping[date] = shiftCounts
        const total = data.punchClock[date] ? dayjs(`${date} ${data.punchClock[date][data.punchClock[date].length - 1]}`, 'YYYY-MM-DD HH:mm')
          .diff(dayjs(`${date} ${data.punchClock[date][0]}`, 'YYYY-MM-DD HH:mm'), 'minutes') : ''
        totalPunchTime += total !== '' && Number(total)

        let punchClockRevise = null
        if (data.punchClockRevise[date]) {
          punchClockRevise = data.punchClockRevise[date].sort((a, b) => {
            if (a > b) {
              return 1
            } else if (a < b) {
              return -1
            } else {
              return 0
            }
          })
        }

        if (data.department !== 'doctor') {
          const tempRow = []
          tempRow.push(dayjs(date).format('M/D'))
          tempRow.push(data.displayName)

          if (data.worktimeType === 'shift') {
            tempRow.push(shiftCounts ? shiftCounts.startTime : ' ')
            tempRow.push(shiftCounts ? shiftCounts.endTime : ' ')
          } else {
            const worktime = worktimePolicyMapping[data.worktimePolicies].worktime
            tempRow.push(worktime[weekday] ? worktime[weekday].startTime : ' ')
            tempRow.push(worktime[weekday] ? worktime[weekday].endTime : ' ')
          }

          tempRow.push(data.punchClock[date] ? data.punchClock[date][0] : ' ')
          tempRow.push(data.punchClock[date] ? data.punchClock[date][data.punchClock[date].length - 1] : ' ')
          tempRow.push(`${(total - (total % 60)) / 60}時 ${total % 60}分`)
          tempRow.push(punchClockRevise && formatDate(punchClockRevise[0]) ? punchClockRevise[0] : ' ')
          tempRow.push(punchClockRevise && !formatDate(punchClockRevise[punchClockRevise.length - 1]) ? punchClockRevise[punchClockRevise.length - 1] : ' ')

          const sortLeaves = leaveMapping[date].sort((a, b) => {
            if (a.startTime < b.startTime) {
              return -1
            } else if (a.startTime > b.startTime) {
              return 1
            } else {
              return 0
            }
          })

          for (const leave of sortLeaves) {
            tempRow.push(LeaveTypeMap[leave.type || 'weekly_leave'])
            tempRow.push(leave.startTime)
            tempRow.push(leave.endTime)
          }

          rows.push(tempRow.join(','))
        } else {
          const tempRow = []
          tempRow.push(dayjs(date).format('M/D'))
          tempRow.push(data.displayName)
          tempRow.push(dateLeaveMappingCache[data.id] && dateLeaveMappingCache[data.id][date] ? RepeatMap[dateLeaveMappingCache[data.id][date].repeats] : ' ')
          tempRow.push(dateLeaveMappingCache[data.id] && dateLeaveMappingCache[data.id][date] ? dateLeaveMappingCache[data.id][date].startTime : ' ')
          tempRow.push(dateLeaveMappingCache[data.id] && dateLeaveMappingCache[data.id][date] ? dateLeaveMappingCache[data.id][date].endTime : ' ')
          tempRow.push(punchClockRevise && formatDate(punchClockRevise[0]) ? punchClockRevise[0] : ' ')
          tempRow.push(punchClockRevise && !formatDate(punchClockRevise[punchClockRevise.length - 1]) ? punchClockRevise[punchClockRevise.length - 1] : ' ')
          tempRow.push(data.punchClock[date] ? data.punchClock[date][0] : ' ')
          tempRow.push(data.punchClock[date] && data.punchClock[date].length > 1 ? data.punchClock[date][data.punchClock[date].length - 1] : ' ')
          tempRow.push(`${(total - (total % 60)) / 60}時 ${total % 60}分`)

          if (dateShiftMappingCache[date]) {
            const sortShift = objectToArray(dateShiftMappingCache[date]).sort((a, b) => {
              if (a.startTime < b.startTime) {
                return -1
              } else if (a.startTime > b.startTime) {
                return 1
              } else {
                return 0
              }
            })

            for (const shift of sortShift) {
              tempRow.push(shift ? RepeatMap[shift.repeats] : ' ')
              tempRow.push(shift ? shift.startTime : ' ')
              tempRow.push(shift ? shift.endTime : ' ')
            }
          }
          _rows.push(tempRow.join(','))
        }
      })

      if (data.department !== 'doctor') {
        rows.push([[`總計,,,,,,${(totalPunchTime - (totalPunchTime % 60)) / 60}時 ${totalPunchTime % 60}分,,,,,,,,`]])
      } else {
        _rows.push([[`總計,,,,,,,,,${(totalPunchTime - (totalPunchTime % 60)) / 60}時 ${totalPunchTime % 60}分`]])
      }
    })

    if (rows.length > 1) {
      exportFields(rows, path)
      setloading(false)
    }
    if (_rows.length > 1) {
      exportFields(_rows, path)
      setloading(false)
    }

    handleClose()
  }

  function onFieldChange() {
    let selectFile = document.getElementById('selectFile')
    let regex = ''

    if (process.env.BRANCH_ENV === 'elysee-aesthetics') {
      regex = /(\d{4})(\d{2})(\d{2})\s(\d{2})(\d{2})\s(?:\d{6})\s((?:[A-Z]{1})\d{6})\s.*/
    } else {
      regex = /(\d{4})(\d{2})(\d{2})\s(\d{2})(\d{2})\s(?:\d{6})\s((?:[A-Z]{2})\d{4})\s.*/
    }

    if (selectFile.files && selectFile.files.length) {
      let count = 0
      const clockRecords = {}
      const employeeIdToUserIdMapping = {}
      for (const user of users) {
        if (user.employeeId) {
          employeeIdToUserIdMapping[user.employeeId] = user.id
        }
      }

      for (let file of selectFile.files) {
        let reader = new FileReader()
        // eslint-disable-next-line no-loop-func
        reader.addEventListener('load', async (env) => {
          count++
          try {
            const lines = env.target.result.split('\n')
            for (const line of lines) {
              const match = regex.exec(line)
              if (match) { // reborn punch clock
                const userId = employeeIdToUserIdMapping[match[6]]
                if (userId) {
                  const date = `${match[1]}-${match[2]}-${match[3]}`
                  if (!clockRecords[date]) clockRecords[date] = {}
                  if (!clockRecords[date][userId]) clockRecords[date][userId] = []
                  clockRecords[date][userId].push(`${match[4]}:${match[5]}`)
                }
              }
            }

            if (count === selectFile.files.length) {
              await callFunction('importPunchClock', { ...clockRecords })
              setloading(false)
              handleClose()
            }
          } catch (ex) {
            setloading(false)
            console.log(ex)
          }
        })
        reader.readAsText(file, 'BIG5')
      }
    }
  }

  function importFile() {
    setloading(true)
    let selectFile = document.getElementById('selectFile')
    selectFile.click()
  }

  return (
    <div>
      {loading && <Backdrop open={true} style={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='sm'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: 'schedule.export.title' })}</DialogTitle>
        <Grid container spacing={1} sx={{ mb: '20px' }}>
          {fields.map(field => createField(field))}
        </Grid>
        <DialogActions>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} md={4}>
              <Button
                variant="contained"
                color="success"
                onClick={() => importFile()}
                sx={{ ml: '12px' }}
              >
                <ImportIcon />
                匯入打卡資料
              </Button>
              <input id='selectFile' type='file' accept='.txt' style={{ display: 'none' }} onChange={() => onFieldChange()} />
            </Grid>
            <Grid item xs={8} sm={8} md={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonProgress
                buttonText='button.export'
                handleClick={() => onExportField()}
                handleClose={() => handleClose()}
                icons={<ExportIcon />}
                loading={false}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ScheduleExportDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ScheduleExportDialog
