import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import Switch from '@mui/material/Switch';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableEx from 'components/EnhancedTableEx';
import ActionDialog from 'components/ActionDialog';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import PunchClockReviseView from './PunchClockReviseView';

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function ReviewPunchClockReviseList({ isAll }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const userMapping = useSelector(state => state.users.data);
  const tableRef = useRef()
  const { currentUser } = useContext(ContextStore)

  const [pendingPunchClockRevise, setPendingPunchClockRevise] = useState([])
  const [historPunchClockRevise, sethistorPunchClockRevise] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [historyStartDate, setHistoryStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [historyEndDate, setHistoryEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [filterText, setFilterText] = useState('')
  const [filterHistoryText, setFilterHistoryText] = useState('')

  const [endOn, setEndOn] = useState(false)

  useEffect(() => {
    setPendingPunchClockRevise([])
    const unsubscribe = firebaseV8().collection('punchClockRevise')
      .where('status', '==', 'pending').onSnapshot(snapshot => {
        const punchClockRevises = []
        snapshot.forEach(doc => {
          punchClockRevises.push({ ...doc.data(), id: doc.id })
        });

        const stepUserLeaves = !isAll ? punchClockRevises.filter(punchClockRevise => {
          const currentStep = punchClockRevise.history.length > 0 ? punchClockRevise.history[punchClockRevise.history.length - 1].step : 0
          return punchClockRevise.steps[currentStep].users.includes(currentUser.key)
        }) : punchClockRevises

        setPendingPunchClockRevise(stepUserLeaves)
      }, err => { })

    return () => unsubscribe()
  }, [location.pathname]);

  useEffect(() => {
    sethistorPunchClockRevise([])
    const unsubscribe = firebaseV8().collection('punchClockRevise')
      .where('date', '>=', historyStartDate).where('date', '<=', historyEndDate)
      .where('status', 'in', ['done', 'void', 'reject']).onSnapshot(snapshot => {
        const punchClockRevises = []
        snapshot.forEach(doc => {
          punchClockRevises.push({ ...doc.data(), id: doc.id })
        });

        if (endOn) {
          sethistorPunchClockRevise(punchClockRevises)
        } else {
          sethistorPunchClockRevise(punchClockRevises.filter(p => !userMapping[p.createdBy].endOn))
        }
      }, err => { })

    return () => unsubscribe()
  }, [historyStartDate, historyEndDate]);


  const formatData = (punchClockRevise) => {
    const newData = { ...punchClockRevise }
    newData.createdBy = userMapping[newData.createdBy].displayName
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.start = `${newData.startDate}`
    newData.statusName = `${formatMessage({ id: `punchClockReviseStatus.${newData.status}` })}`
    newData.end = `${newData.endDate}`
    newData.textColor = statusColor[newData.status]
    return newData
  }

  async function handleExecute(data) {
    const { text } = data
    const { filed, punchClockReviseId } = dialogData
    setLoading(true)

    try {
      await callFunction('savePunchClockRevise', {
        ...filed,
        id: punchClockReviseId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  function HistoryHandleChange(value) {
    setFilterHistoryText(value)
  }

  function handleChange(value) {
    setFilterText(value)
  }

  function getToolbox(isHitory) {
    return <>
      {isHitory && <DateRangePickerField
        startDate={isHitory ? historyStartDate : startDate}
        endDate={isHitory ? historyEndDate : endDate}
        onStartDateChanged={isHitory ? setHistoryStartDate : setStartDate}
        onEndDateChanged={isHitory ? setHistoryEndDate : setEndDate}
      />}
      <Grid item xs={12} sm={4} md={4} style={{ paddingRight: '10px' }}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.createdBy' })}
          onChange={(e) => isHitory ? HistoryHandleChange(e.target.value) : handleChange(e.target.value)}
          variant="outlined"
          fullWidth
          value={isHitory ? filterHistoryText : filterText}
        />
      </Grid>
    </>
  }

  const headerCells = [
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `punchClockRevise.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdBy' },
    { field: 'createdAt' },
    { field: 'date' },
    { field: 'time' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  const historyHeaderCells = [
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `punchClockRevise.table.${c.text}` }); return c })

  const historyRowCells = [
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'time' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  function filterItem(isHistory) {
    if (isHistory) {
      if (filterHistoryText !== '') {
        return historPunchClockRevise.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else {
        return historPunchClockRevise
      }
    } else {
      if (filterText !== '') {
        return pendingPunchClockRevise.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else {
        return pendingPunchClockRevise
      }
    }
  }

  function exportProfile() {
    tableRef.current.saveToFile(`PunchClockRevises_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`)
  }

  const filterHistoryPunchClockRevise = filterItem(true);
  const filterPunchClockRevise = filterItem();

  return (
    <div>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'punchClockRevise.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid container spacing={1} sx={{ mt: '10px', mb: '40px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'punchClockRevise.table.pending'}
            toolbox={getToolbox()}
          />
          <EnhancedTable
            defaultOrder="desc"
            defaultOrderField="start"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filterPunchClockRevise.map(r => formatData(r))}
            getExpandContent={punchClockRevise =>
              <PunchClockReviseView currentUser={currentUser} punchClockRevise={punchClockRevise} isAll={isAll} type='review' />
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'punchClockRevise.table.history'}
            toolbox={getToolbox(true)}
          />
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            一般
            <Switch
              checked={endOn}
              onChange={e => setEndOn(e.target.checked)}
              color="primary"
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            詳細
          </Grid>
          <EnhancedTableEx
            ref={tableRef}
            defaultOrder="desc"
            defaultOrderField="start"
            headerCells={historyHeaderCells}
            rowCells={historyRowCells}
            tableData={filterHistoryPunchClockRevise.map(r => formatData(r))}
            getHeaderActionIcons={() => isAll ? (
              <IconButton
                onClick={() => exportProfile()}
                size="large">
                <GetAppIcon></GetAppIcon>
                <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
              </IconButton>
            ) : null}
            getActionIcons={revise => <>
              {(!revise.void || (revise.void && revise.status === 'reject')) && <span>
                <Tooltip title={formatMessage({ id: 'button.edit' })}>
                  <IconButton
                    onClick={() => navigate(`/leaveOvertimeWork/punchClockRevise/edit/${revise.createdBy}/${revise.id}`)}
                    size="large">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={formatMessage({ id: 'button.void' })}>
                  <IconButton
                    onClick={() => setDialogData({ action: 'void', title: '', filed: revise, punchClockReviseId: revise.id })}
                    size="large">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </span>}
            </>
            }
            getExpandContent={revise =>
              <PunchClockReviseView currentUser={currentUser} punchClockRevise={revise} isHistory={true} isAll={isAll} type='review' />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

ReviewPunchClockReviseList.propTypes = {
  isAll: PropTypes.bool,
};

export default ReviewPunchClockReviseList;
